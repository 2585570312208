import React from 'react'
import { Typography, useTheme, alpha } from '@material-ui/core'
import { blendColors } from '../../utils/utils'




const Logo = () => {
    const theme = useTheme();

    const texGradient = `linearGradient(#FAFAFA, #E0E0E0)`;


    const rgb = theme.palette.primary.dark

const textShadow = `${rgb} 0px 0px, ${rgb} -1px 1px, ${rgb} -2px 2px, ${rgb} -3px 3px, ${rgb} -4px 4px, ${rgb} -5px 5px, ${rgb} -6px 6px, ${rgb} -7px 7px, ${rgb} -8px 8px, ${rgb} -9px 9px, ${rgb} -10px 10px, ${rgb} -11px 11px, ${rgb} -12px 12px, ${rgb} -13px 13px, ${rgb} -14px 14px, ${rgb} -15px 15px, ${rgb} -16px 16px, ${rgb} -17px 17px, ${rgb} -18px 18px, ${rgb} -19px 19px, ${rgb} -20px 20px, ${rgb} -21px 21px, ${rgb} -22px 22px, ${rgb} -23px 23px, ${rgb} -24px 24px, ${rgb} -25px 25px, ${rgb} -26px 26px, ${rgb} -27px 27px, ${rgb} -28px 28px, ${rgb} -29px 29px, ${rgb} -30px 30px, ${rgb} -31px 31px, ${rgb} -32px 32px, ${rgb} -33px 33px, ${rgb} -34px 34px, ${rgb} -35px 35px, ${rgb} -36px 36px, ${rgb} -37px 37px, ${rgb} -38px 38px, ${rgb} -39px 39px, ${rgb} -40px 40px, ${rgb} -41px 41px, ${rgb} -42px 42px, ${rgb} -43px 43px, ${rgb} -44px 44px, ${rgb} -45px 45px, ${rgb} -46px 46px, ${rgb} -47px 47px, ${rgb} -48px 48px, ${rgb} -49px 49px, ${rgb} -50px 50px, ${rgb} -51px 51px, ${rgb} -52px 52px, ${rgb} -53px 53px, ${rgb} -54px 54px, ${rgb} -55px 55px, ${rgb} -56px 56px, ${rgb} -57px 57px, ${rgb} -58px 58px, ${rgb} -59px 59px, ${rgb} -60px 60px, ${rgb} -61px 61px, ${rgb} -62px 62px, ${rgb} -63px 63px, ${rgb} -64px 64px, ${rgb} -65px 65px, ${rgb} -66px 66px, ${rgb} -67px 67px, ${rgb} -68px 68px, ${rgb} -69px 69px, ${rgb} -70px 70px
`

const rgb2 = blendColors(theme.palette.primary.main, theme.palette.primary.dark, 0.5); 
const textShadow2 = `${rgb2} 0px 0px, ${rgb2} -1px 1px, ${rgb2} -2px 2px, ${rgb2} -3px 3px, ${rgb2} -4px 4px, ${rgb2} -5px 5px, ${rgb2} -6px 6px, ${rgb2} -7px 7px, ${rgb2} -8px 8px, ${rgb2} -9px 9px, ${rgb2} -10px 10px, ${rgb2} -11px 11px, ${rgb2} -12px 12px, ${rgb2} -13px 13px, ${rgb2} -14px 14px, ${rgb2} -15px 15px, ${rgb2} -16px 16px, ${rgb2} -17px 17px, ${rgb2} -18px 18px, ${rgb2} -19px 19px, ${rgb2} -20px 20px, ${rgb2} -21px 21px, ${rgb2} -22px 22px, ${rgb2} -23px 23px, ${rgb2} -24px 24px, ${rgb2} -25px 25px, ${rgb2} -26px 26px, ${rgb2} -27px 27px, ${rgb2} -28px 28px, ${rgb2} -29px 29px, ${rgb2} -30px 30px, ${rgb2} -31px 31px, ${rgb2} -32px 32px, ${rgb2} -33px 33px, ${rgb2} -34px 34px, ${rgb2} -35px 35px, ${rgb2} -36px 36px, ${rgb2} -37px 37px, ${rgb2} -38px 38px, ${rgb2} -39px 39px, ${rgb2} -40px 40px, ${rgb2} -41px 41px, ${rgb2} -42px 42px, ${rgb2} -43px 43px, ${rgb2} -44px 44px, ${rgb2} -45px 45px, ${rgb2} -46px 46px, ${rgb2} -47px 47px, ${rgb2} -48px 48px, ${rgb2} -49px 49px, ${rgb2} -50px 50px, ${rgb2} -51px 51px, ${rgb2} -52px 52px, ${rgb2} -53px 53px, ${rgb2} -54px 54px, ${rgb2} -55px 55px, ${rgb2} -56px 56px, ${rgb2} -57px 57px, ${rgb2} -58px 58px, ${rgb2} -59px 59px, ${rgb2} -60px 60px, ${rgb2} -61px 61px, ${rgb2} -62px 62px, ${rgb2} -63px 63px, ${rgb2} -64px 64px, ${rgb2} -65px 65px, ${rgb2} -66px 66px, ${rgb2} -67px 67px, ${rgb2} -68px 68px, ${rgb2} -69px 69px, ${rgb2} -70px 70px
`

    return (
        <div style={{ textAlign: 'center', alignItems: 'center' }}>
            <Typography variant='h1' style={{ fontSize: 30, display: 'inline-block', background:texGradient, position:"relative", textShadow: textShadow , zIndex:2}}>
                pay
            </Typography>
            <Typography variant='body1' style={{ fontSize: 30, display: 'inline-block', color:'#FAFAFA',position:"relative", textShadow: textShadow2 , zIndex:1}}>
                calculator
            </Typography>
        </div>
    )

}


export default Logo;