export let NAV_HEIGHT_SMALL = 40;
export let NAV_HEIGHT = 82;
export let HEADER_HEIGHT = 500;
export let HEADER_MOBILE_HEIGHT = 600;
export const PANEL_HEADER_HEIGHT = 40;

export const MAX_WIDTH = 3000;

export const ZERO_VALUE = 0.000001;

export const NBSP = '\u00a0';

export const SCALE1 = "scale1"; // Where the tax-free threshold is not claimed
export const SCALE2 = "scale2"; // Where the employee claimed the tax-free threshold
export const SCALE3 = "scale3"; // Foreign residents
export const SCALE4 = "scale4"; // Where a tax file number (TFN) was not provided by employee
export const SCALE5 = "scale5"; // Where the employee claimed the FULL exemption from Medicare levy
export const SCALE6 = "scale6"; // Where the employee claimed the HALF exemption from Medicare levy

export const EMAIL_ADDRESS = 'mailto:hello@paycalculator.com';

export const URL = 'https://paycalculator.com.au';
export const API_PATH = 'https://api.paycalculator.com';
export const SHORT_URI = 'payc.com.au/';
export const SHORT_URL = `https://${SHORT_URI}`;
export const MORTAGE_MONSTER = 'https://mortgage.monster';

export const OLD_CALCULATOR_LINK = '/classic/index.html';
export const CALCULATOR_LINK = '/';
export const TAX_INFO_LINK = '/tax-info';
export const STUDENT_LOAN_LINK = '/student-loan';
export const TAX_DISTRIBUTION_LINK = '/tax-distribution';
export const PRIVACY_POLICY_LINK = '/privacy';
export const ACCEPTABLE_USE_LINK = '/acceptable-use';


export const COOKIES_LINK = "/cookies";
export const SURVEY_LINK = "https://www.surveymonkey.com/r/TRCHNWC";

export const STUDENT_LOAN_INDEXATION_VALUE = 0.02;
export const STUDENT_LOAN_TYPICAL = 25000;
export const WAGE_INDEXATION = 0.04;


// reference links
export const ORDINARY_EARNINGS = 'https://www.australiansuper.com/employers/employers-articles/2020/10/paying-employees-super-on-overtime-bonuses';
export const STUDENT_LOAN_INDEXATION = 'https://www.ato.gov.au/Rates/Study-and-training-loan-indexation-rates/';
export const STUDENT_LOAN_THRESHOLDS = 'https://www.studyassist.gov.au/paying-back-your-loan/loan-repayment';
export const SUPER_CO_CONTRIBUTION = "https://www.ato.gov.au/individuals/super/in-detail/growing-your-super/super-co-contribution/";
export const HELP_REPAYMENT_SOURCE = 'https://www.ato.gov.au/Rates/HELP,-TSL-and-SFSS-repayment-thresholds-and-rates/';
export const HECS_HELP_INFO = 'https://www.studyassist.gov.au/sites/default/files/ed19-0226_hecs-help_booklet_acc2.pdf?v=1584566164';
export const FEE_HELP_INFO = 'https://www.studyassist.gov.au/help-loans/fee-help';
export const OS_HELP_INFO = 'https://www.studyassist.gov.au/help-loans/os-help-and-overseas-study';
export const SA_HELP_INFO = 'https://www.studyassist.gov.au/help-loans/sa-help';
export const SSL_INFO = 'https://www.servicesaustralia.gov.au/individuals/services/centrelink/student-start-loan';
export const TSL_INFO = 'https://austapprent.govcms.gov.au/sites/default/files/2019-06/AISS%20Final.pdf';
export const CPS_INFO = 'https://www.studyassist.gov.au/sites/studyassist/files/ed19-0005_-_he_-_2019_help_publications_-_handbook_acc.pdf?v=1554852826';
export const MYSKILLS_INFO = 'https://www.myskills.gov.au/';
export const VET_INFO = 'https://www.employment.gov.au/vet-student-loans';
export const APPRENTISHIP_INFO = 'https://www.australianapprenticeships.gov.au/aus-apprenticeships-incentives';
export const OVERSEAS_REPAYMENT_INFO = 'https://www.ato.gov.au/Individuals/Study-and-training-support-loans/Overseas-repayments/#OverseasTravelNotification';
export const WORLDWIDE_INCOME_INFO = 'https://www.ato.gov.au/Individuals/Study-and-training-support-loans/Overseas-repayments/#WhatdoIneedtolodge" title="report your worldwide income';
export const NON_LODGEMENT_INFO = 'https://www.ato.gov.au/Individuals/Study-and-training-support-loans/Overseas-repayments/#Assessmentmethodsfornonresidentforeignso';
export const RESIDENCY_INFO = 'https://www.ato.gov.au/Individuals/International-tax-for-individuals/In-detail/Residency/Residency---the-resides-test/';
export const BUDGET_2020_INFO = 'https://archive.budget.gov.au/2019-20/index.htm';
export const BUDGET_2021_INFO = 'https://archive.budget.gov.au/2020-21/index.htm';
export const BUDGET_2022 = 'https://budget.gov.au/index.htm';
export const BUDGET_2023_INFO = 'https://budget.gov.au/index.htm';
export const AWARD_RATES = 'https://www.fairwork.gov.au/pay/minimum-wages/pay-guides';
export const WORKING_HOLIDAY_MAKERS_TAX = 'https://www.ato.gov.au/rates/schedule-15---tax-table-for-working-holiday-makers/';
export const NON_RESIDENT_TAX = 'https://www.ato.gov.au/Individuals/Coming-to-Australia-or-going-overseas/Your-tax-residency/';

export const MAXIMUM_CONTRIBUTIONS_BASE = 'https://www.ato.gov.au/rates/key-superannuation-rates-and-thresholds/?anchor=Maximumsupercontributionbase';
export const EXTRA_PAYMENTS = 'https://www.ato.gov.au/Calculators-and-tools/Tax-withheld-calculator/?page=2#How_to_use_the_tax_withheld_for_individuals_calculator';
export const ATO_CALCULATORS = 'https://www.ato.gov.au/Calculators-and-tools/Tax-withheld-calculator/'
export const ATO_FORMULAS = 'https://www.ato.gov.au/tax-rates-and-codes/payg-withholding-schedule-1-statement-of-formulas-for-calculating-amounts-to-be-withheld';
export const ATO_DAILY_TABLES = 'https://www.ato.gov.au/tax-rates-and-codes/tax-table-daily-and-casual-workers';
export const ATO_WEEKLY_TABLES = 'https://www.ato.gov.au/tax-rates-and-codes/tax-table-weekly';
export const ATO_FORTNIGHTLY_TABLES = 'https://www.ato.gov.au/tax-rates-and-codes/tax-table-fortnightly';
export const ATO_MONTHLY_TABLES = 'https://www.ato.gov.au/tax-rates-and-codes/tax-table-monthly';
export const ATO_SAMPLE_DATA = 'https://www.ato.gov.au/tax-rates-and-codes/payg-withholding-schedule-1-statement-of-formulas-for-calculating-amounts-to-be-withheld/sample-data';

export const ATO_STATEMENT_OF_FORMULAS = 'https://www.ato.gov.au/tax-rates-and-codes/payg-withholding-schedule-1-statement-of-formulas-for-calculating-amounts-to-be-withheld/using-a-formula';

export const ATO_PERCENTILE_DISTRIBUTION = 'https://data.gov.au/data/dataset/taxation-statistics-2021-22/resource/aedf0d2f-70e8-49b8-a394-924bbe01fbbe'
export const ATO_PERCENTILE_DISTRIBUTION_HISTORIC = 'https://data.gov.au/data/dataset/taxation-statistics-2021-22'

export const CAPTIAL_GAINS = 'https://www.ato.gov.au/general/capital-gains-tax/';
export const BUSINESS_INCOME_LOSS = 'https://www.ato.gov.au/Business/Income-and-deductions-for-business/Business-losses/';

export const ALLOWANCE_TYPES_LINK = 'https://www.ato.gov.au/business/payg-withholding/payments-you-need-to-withhold-from/payments-to-employees/allowances-and-reimbursements/withholding-for-allowances/';
export const NOTICE_OF_INTENT_TO_CLAIM = 'https://www.ato.gov.au/Forms/Notice-of-intent-to-claim-or-vary-a-deduction-for-personal-super-contributions/';

export const SALARY_SACRIFICE = 'https://www.ato.gov.au/individuals-and-families/jobs-and-employment-types/working-as-an-employee/salary-sacrificing-for-employees'
export const ELIGIBLE_DEDUCTIONS = 'https://www.ato.gov.au/individuals-and-families/income-deductions-offsets-and-records/deductions-you-can-claim'

// REPORTABLE SUPER
export const REPORTABLE_SUPER = 'https://www.ato.gov.au/businesses-and-organisations/super-for-employers/setting-up-super-for-your-business/identify-reportable-employer-super-contributions'

//FTB
export const FTB_ELIGIBILITY = 'https://www.servicesaustralia.gov.au/who-can-get-family-tax-benefit?context=22151';
export const FTB_MIFA_EXEMPT = 'https://www.servicesaustralia.gov.au/income-test-for-family-tax-benefit-part?context=22151';
export const FTB_CARE_PERCENT = 'https://www.servicesaustralia.gov.au/how-your-percentage-care-affects-your-child-support-payments?context=21911';


//Inflation
export const RBA_INFLATION_OUTLOOK = 'https://www.rba.gov.au/media-releases/';
export const RBA_INFLATION_HISTORIC = 'https://www.rba.gov.au/inflation/measures-cpi.html';
export const WAGE_PRICE_INDEX = 'https://www.abs.gov.au/statistics/economy/price-indexes-and-inflation/wage-price-index-australia/latest-release';

// carry -forward super
export const CARRY_FORWARD_INFO = 'https://www.ato.gov.au/Rates/Key-superannuation-rates-and-thresholds/?page=2';
export const SPOUSE_SUPER_INFO = 'https://www.ato.gov.au/Individuals/myTax/2022/In-detail/Super-contributions-on-behalf-of-your-spouse/';
export const MEDICARE_LEVY_EXPEMTION_INFO = 'https://www.ato.gov.au/Individuals/Medicare-and-private-health-insurance/Medicare-levy/medicare-levy-exemption/';
export const MEDICARE_LEVY_DEPENDANTS_INFO = 'https://www.ato.gov.au/Individuals/Medicare-and-private-health-insurance/Medicare-levy/Medicare-levy-exemption/Dependants-for-Medicare-levy-exemption/';
export const MEDICARE_LEVY_VARIATION_INFO = 'https://www.ato.gov.au/Forms/Medicare-levy-variation-declaration/';
export const SAPTO_INFO = 'https://www.ato.gov.au/calculators-and-tools/beneficiary-tax-offset-and-seniors-and-pensioner-tax-offset-calculator/';
export const REBATE_INCOME_INFO = "https://www.ato.gov.au/individuals-and-families/your-tax-return/instructions-to-complete-your-tax-return/paper-tax-return-instructions/2024/supporting-information/rebate-income-2024#ato-Whatisrebateincome";
export const TAXABLE_DEDUCTIONS_INFO = 'https://www.ato.gov.au/calculators-and-tools/beneficiary-tax-offset-and-seniors-and-pensioner-tax-offset-calculator/';

// Medicare
export const STUDENT_LOAN_EXPEMTION_INFO = 'https://www.ato.gov.au/individuals/study-and-training-support-loans/compulsory-repayments/';
export const APPROVED_PROVIDER = 'https://www.privatehealth.gov.au/dynamic/healthfundlist.aspx';
export const MEDICARE_LEVY = 'https://www.health.gov.au/health-topics/medicare';
export const MEDICARE_LEVY_SURCHARGE = 'https://healthinsurancecomparison.com.au/guide/medicare-levy-and-surcharge-difference/';
export const MEDICARE_COMAPRISON = 'https://healthinsurancecomparison.com.au/guide/medicare-levy-and-surcharge-difference/';

export const SMALL_BUSINESS_TAX_OFFSET = 'https://www.ato.gov.au/business/income-and-deductions-for-business/in-detail/small-business-income-tax-offset/?anchor=Working_out_your_offset#Working_out_your_offset';
export const OTHER_INCOME = 'https://www.ato.gov.au/Individuals/Tax-return/2021/Supplementary-tax-return/Income-questions-13-24/24-Other-income-2021/';
export const TAX_OFFSETS_INFO = 'https://www.ato.gov.au/individuals-and-families/income-deductions-offsets-and-records/tax-offsets'
export const FRANKING_CREDITS_INFO = 'https://www.ato.gov.au/forms-and-instructions/you-and-your-shares-2023/franking-tax-offsets'


export const SUB_PAGES = [
  CALCULATOR_LINK,
  TAX_INFO_LINK,
  STUDENT_LOAN_LINK,
  TAX_DISTRIBUTION_LINK,
  PRIVACY_POLICY_LINK,
  ACCEPTABLE_USE_LINK,
  COOKIES_LINK,
];

export const MONTH_NAMES = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
export const MONTH_NAMES_SHORT = ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];